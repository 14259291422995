import React, { useState } from 'react';
import { TextField, Button, Container, Typography, Avatar, CssBaseline } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import './Login.css';

const Login = ({ onLogin }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    localStorage.setItem('userData', email);
    onLogin(email, password);
  };

  return (
    <div className="login-page">
      <Container component="main" maxWidth="xs" className="login-container">
        <CssBaseline />
        <Avatar>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5" className="login-title">
          LOGIN
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            name="email"
            autoComplete="email"
            placeholder='Email'
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="login-input"
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            placeholder='Password'
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="login-input"
          />
          {error && <Typography color="error">{error}</Typography>}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className="login-button"
          >
            Log In
          </Button>
          <div className="login-footer">
            <Typography>
              Don't have an account? <a href="/register">Register</a>
            </Typography>
          </div>
        </form>
      </Container>
    </div>
  );
};

export default Login;
