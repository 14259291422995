const modules = {
  'Mempertunjukkan Pengelolaan Berkelanjutan secara Efektif': [
    'Sistem Pengelolaan Keberlanjutan',
    'Kepatuhan terhadap hukum',
    'Pelaporan dan komunikasi',
    'Pelibatan Staf',
    'Pengalaman Pelanggan',
    'Promosi yang tepat',
    'Kepatuhan',
    'Dampak dan keutuhan',
    'Praktik dan Bahan Berkelanjutan',
    'Akses untuk semua',
    'Hak atas lahan, air dan kepemilikan',
    'Informasi dan interpretasi',
    'Pelibatan destinasi',
  ],
  'Memaksimalkan manfaat sosial dan ekonomi bagi masyarakat lokal dan meminimalkan dampak negatif': [
    'Dukungan terhadap masyarakat',
    'Pekerjaan bagi masyarakat lokal',
    'Pembelian barang dan jasa lokal',
    'Wirausahawan lokal',
    'Eksploitasi dan pelecehan',
    'Peluang yang sama',
    'Kerja yang layak',
    'Layanan masyarakat',
    'Mata-pencaharian lokal',
  ],
  'Memaksimalkan manfaat bagi warisan budaya dan meminimalkan dampak negatif': [
    'Interaksi budaya',
    'Melindungi warisan budaya',
    'Menyajikan budaya dan warisan',
    'Artefak',
  ],
  'Memaksimalkan manfaat bagi lingkungan dan meminimalkan dampak negatif': [
    'Pembelian yang ramah-lingkungan',
    'Pembelian yang efisien',
    'Konservasi energi',
    'Konservasi air',
    'Emisi gas rumah-kaca',
    'Transportasi',
    'Air limbah',
    'Limbah padat',
    'Bahan berbahaya dan beracun',
    'Meminimalkan pencemaran',
    'Konservasi keanekaragaman hayati',
    'Species invasif',
    'Kunjungan ke situs alami',
    'Interaksi dengan hidupan-liar',
    'Kesejahteraan satwa',
    'Pengambilan dan perdagangan hidupan-liar',
  ],
};

export default modules;
