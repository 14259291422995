import axios from 'axios';
import Properties from './properties';

const API_URL = `${Properties().baseUrl}/api/users`;

const register = (email, password) => {
  return axios.post(`${API_URL}/register`, { email, password });
};

const login = (email, password) => {
  return axios.post(`${API_URL}/login`, { email, password });
};

const userData = (email) => {
  return axios.get(`${API_URL}/${email}`, { email });
};

const userAnswerDataData = (userId) => {
  return axios.get(`${API_URL}/user/${userId}`, { userId });
};

const saveUserAnswer = (modelUser) => {
  return axios.post(`${API_URL}/answers-submit`, { modelUser });
};

export { register, login, userData, saveUserAnswer, userAnswerDataData };
