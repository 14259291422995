// useAnswers.js

const defaultAnswers =  {
    'Sistem Pengelolaan Keberlanjutan': Array(10).fill(null),
    'Kepatuhan terhadap hukum': Array(10).fill(null),
    'Pelaporan dan komunikasi': Array(10).fill(null),
    'Pelibatan Staf': Array(10).fill(null),
    'Pengalaman Pelanggan': Array(10).fill(null),
    'Promosi yang tepat': Array(10).fill(null),
    'Kepatuhan': Array(10).fill(null),
    'Dampak dan keutuhan': Array(10).fill(null),
    'Praktik dan Bahan Berkelanjutan': Array(10).fill(null),
    'Akses untuk semua': Array(10).fill(null),
    'Hak atas lahan, air dan kepemilikan': Array(10).fill(null),
    'Informasi dan interpretasi': Array(10).fill(null),
    'Pelibatan destinasi': Array(10).fill(null),
    'Dukungan terhadap masyarakat': Array(10).fill(null),
    'Pekerjaan bagi masyarakat lokal': Array(10).fill(null),
    'Pembelian barang dan jasa lokal': Array(10).fill(null),
    'Wirausahawan lokal': Array(10).fill(null),
    'Eksploitasi dan pelecehan': Array(10).fill(null),
    'Peluang yang sama': Array(10).fill(null),
    'Kerja yang layak': Array(10).fill(null),
    'Layanan masyarakat': Array(10).fill(null),
    'Mata-pencaharian lokal': Array(10).fill(null),
    'Interaksi budaya': Array(10).fill(null),
    'Melindungi warisan budaya': Array(10).fill(null),
    'Menyajikan budaya dan warisan': Array(10).fill(null),
    'Artefak': Array(10).fill(null),
    'Pembelian yang ramah-lingkungan': Array(10).fill(null),
    'Pembelian yang efisien': Array(10).fill(null),
    'Konservasi energi': Array(10).fill(null),
    'Konservasi air': Array(10).fill(null),
    'Emisi gas rumah-kaca': Array(10).fill(null),
    'Transportasi': Array(10).fill(null),
    'Air limbah': Array(10).fill(null),
    'Limbah padat': Array(10).fill(null),
    'Bahan berbahaya dan beracun': Array(10).fill(null),
    'Meminimalkan pencemaran': Array(10).fill(null),
    'Konservasi keanekaragaman hayati': Array(10).fill(null),
    'Species invasif': Array(10).fill(null),
    'Kunjungan ke situs alami': Array(10).fill(null),
    'Interaksi dengan hidupan-liar': Array(10).fill(null),
    'Kesejahteraan satwa': Array(10).fill(null),
    'Pengambilan dan perdagangan hidupan-liar': Array(10).fill(null),
};

export default defaultAnswers;
