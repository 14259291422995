import React, { useState, useEffect } from 'react';
import './App.css';
import { FaBars, FaAngleLeft, FaChevronRight, FaChevronDown } from 'react-icons/fa';
import { IconButton } from '@mui/material';
import modules from './Modules.js';
import questionList from './QuestionList.js';
import useAnswers from './userAnswers.js';
import defaultAnswers from './userDefaultAnswers.js';
import logoBanyuwangi from './Logo-Banyuwangi.png';
import logoPoliwangi from './Logo-Poliwangi.png';
import Login from './Login.js';
import { login } from './Api.js';
import Properties from './properties.js';

function App() {
  const [currentModule, setCurrentModule] = useState('Module A');
  const [currentChild, setCurrentChild] = useState(null);
  const [scoreModule1, setScoreModule1] = useState(null);
  const [scoreModule2, setScoreModule2] = useState(null);
  const [scoreModule3, setScoreModule3] = useState(null);
  const [scoreModule4, setScoreModule4] = useState(null);
  const [answers, setAnswers] = useAnswers();
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [expandedModule, setExpandedModule] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false); 
  const [userAnswersHistory, setUserAnswersHistory] = useState([]);

  useEffect(() => {
    const userToken = localStorage.getItem('userToken');
    if (userToken) {
      setIsLoggedIn(true);
    }
  }, []);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleConfirm = async () => {
  const userAnswerId = localStorage.getItem('userUuid'); // Replace with the actual ID

  try {
      // Lock the answers by sending a PUT request
      const response = await fetch(`${Properties().baseUrl}/api/users/answers-locked/${userAnswerId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        // Reset answers to default after locking
        console.log('Answers reset to default', response);
        setAnswers(defaultAnswers);
        // Close the modal after confirming
        closeModal();

        // Fetch updated history data
        const responseHistory = await fetch(`${Properties().baseUrl}/api/users/user/isNotLocked/${userAnswerId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (!responseHistory.ok) {
          console.error('Failed to fetch history data');
        } else {
          const dataHistory = await responseHistory.json();
          setUserAnswersHistory(dataHistory);
          // window.location.reload();
        }
      } else {
        console.error('Failed to lock answers');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleAnswer = (questionIndex, answer) => {
    setAnswers((prevAnswers) => {
      const updatedAnswers = { ...prevAnswers };
      if (currentChild) {
        updatedAnswers[currentChild][questionIndex] = answer;
      }
      return updatedAnswers;
    });
  };

  const isModuleComplete = (module) => {
    return questionList[module].every((_, index) => answers[module][index] !== null);
  };

  const calculatePercentageYes = (filteredData, totalQuestions) => {
      let totalYesAnswers = 0;

      Object.values(filteredData).forEach(answersArray => {
        totalYesAnswers += answersArray.filter(answer => answer === 'true' || answer === 'Yes').length;
      });      
      const percentageYes = (totalYesAnswers / totalQuestions) * 100;
      return percentageYes;
  };

  const submitAnswers = async (isFinal = false) => {
    try {
      const email = localStorage.getItem('userData');
      const response = await fetch(`${Properties().baseUrl}/api/users/${email}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      
      if (!response.ok) {
        console.error('Error fetching user data:', response.statusText);
        return;
      }
      const dataUser = await response.json();
      localStorage.setItem('userUuid', dataUser.id);

      const compactAnswers = {};
      let totalQuestions = 0;
      let totalYesAnswers = 0;
      const categoryData = modules[expandedModule];
      
      categoryData.forEach(category => {
        if (questionList.hasOwnProperty(category)) {
          totalQuestions += questionList[category].length;
        }
      });

      const filterAnswers = () => {
        const filteredAnswers = categoryData.reduce((acc, key) => {
          if (answers[key]) {
            acc[key] = answers[key];
          }
          return acc;
        }, {});

        return filteredAnswers;
      };
      const filteredData = filterAnswers();      
      localStorage.setItem('answers', JSON.stringify(compactAnswers));
      let modelUserAnswer = null;

      if(expandedModule === 'Mempertunjukkan Pengelolaan Berkelanjutan secara Efektif'){
        const scoreModule1 = calculatePercentageYes(filteredData, totalQuestions);
        setScoreModule1(parseToPercentage(scoreModule1));
        modelUserAnswer = {
          id: localStorage.getItem('userUuid'),
          answer_module_1: JSON.stringify(filteredData),
          isFinal,
          score_module_1: scoreModule1,
          is_locked: false,
        };
        alert(`Final answers submitted successfully! You scored ${parseToPercentage(scoreModule1)}%.`);
      } else if (expandedModule === 'Memaksimalkan manfaat sosial dan ekonomi bagi masyarakat lokal dan meminimalkan dampak negatif'){
        const scoreModule2 = calculatePercentageYes(filteredData, totalQuestions);
        setScoreModule2(parseToPercentage(scoreModule2));
         modelUserAnswer = {
          id: localStorage.getItem('userUuid'),
          answer_module_2: JSON.stringify(filteredData),
          isFinal,
          score_module_2: scoreModule2,
          is_locked: false,
        };
        alert(`Final answers submitted successfully! You scored ${parseToPercentage(scoreModule2)}%.`);
      } else if (expandedModule === 'Memaksimalkan manfaat bagi warisan budaya dan meminimalkan dampak negatif'){
        const scoreModule3 = calculatePercentageYes(filteredData, totalQuestions);
        setScoreModule3(parseToPercentage(scoreModule3));
         modelUserAnswer = {
          id: localStorage.getItem('userUuid'),
          answer_module_3: JSON.stringify(filteredData),
          isFinal,
          score_module_3: parseToPercentage(scoreModule3),
          is_locked: false,
        };
        alert(`Final answers submitted successfully! You scored ${parseToPercentage(scoreModule3)}%.`);
      } else if (expandedModule === 'Memaksimalkan manfaat bagi lingkungan dan meminimalkan dampak negatif'){
        const scoreModule4 = calculatePercentageYes(filteredData, totalQuestions);
        setScoreModule4(parseToPercentage(scoreModule4));
         modelUserAnswer = {
          id: localStorage.getItem('userUuid'),
          answer_module_4: JSON.stringify(filteredData),
          isFinal,
          score_module_4: scoreModule4,
          is_locked: false,
        };
        alert(`Final answers submitted successfully! You scored ${parseToPercentage(scoreModule4)}%.`);
      }

      const responseAnswer = await fetch(`${Properties().baseUrl}/api/users/answers-submit`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(modelUserAnswer),
      });

      if (!responseAnswer.ok) {
        throw new Error('Network response was not ok');
      }
    } catch (error) {
      console.error('Error when saving data', error);
      alert('Error when saving data');
    }
  };

  const toggleModule = (module) => {
    setExpandedModule(expandedModule === module ? null : module);
  };

  const parseToPercentage = (data) => {
    if(data === null){
      return 0;
    }
    const scoreFloat = parseFloat(data).toFixed(2);
    return scoreFloat;
  };

  const handleLogin = async (email, password) => {
    try {
      const response = await login(email, password);
      setUser(response.data);
      setIsLoggedIn(true);
      localStorage.setItem('userToken', response.data.token);

      const response2 = await fetch(`${Properties().baseUrl}/api/users/${email}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response2.ok) {
        console.error('Error fetching user data:', response2.statusText);
        return;
      }

      const dataUser = await response2.json();
      localStorage.setItem('userUuid', dataUser.id);

      const responseSavedAnswers = await fetch(`${Properties().baseUrl}/api/users/user/${dataUser.id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (responseSavedAnswers.status === 404) {
        console.log("nilainya fullAnswerAtas", defaultAnswers);
        setAnswers(defaultAnswers);
        setScoreModule1(0);
        setScoreModule2(0);
        setScoreModule3(0);
        setScoreModule4(0);
        return true;
      }

      const savedAnswers = await responseSavedAnswers.json();
      const answerModule1 = savedAnswers.answer_module_1;
      const answerModule2 = savedAnswers.answer_module_2;
      const answerModule3 = savedAnswers.answer_module_3;
      const answerModule4 = savedAnswers.answer_module_4;
      const fullAnswers = { ...defaultAnswers };

      if(typeof answerModule1 !== null){
        const parsedAnswersModule1 = JSON.parse(savedAnswers.answer_module_1);
        setScoreModule1(parseToPercentage(JSON.parse(savedAnswers.score_module_1)));
        for (const category in parsedAnswersModule1) {
          if (parsedAnswersModule1.hasOwnProperty(category)) {
            fullAnswers[category] = fullAnswers[category].map((item, index) => {
              return parsedAnswersModule1[category][index] || item;
            });
          }
        }
      }
      if(typeof answerModule2 !== null){
        const parsedAnswersModule2 = JSON.parse(savedAnswers.answer_module_2);
        setScoreModule2(parseToPercentage(JSON.parse(savedAnswers.score_module_2)));
        for (const category in parsedAnswersModule2) {
          if (parsedAnswersModule2.hasOwnProperty(category)) {
            fullAnswers[category] = fullAnswers[category].map((item, index) => {
              return parsedAnswersModule2[category][index] || item;
            });
          }
        }
      }
      if(typeof answerModule3 !== null){
        const parsedAnswersModule3 = JSON.parse(savedAnswers.answer_module_3);
        setScoreModule3(parseToPercentage(JSON.parse(savedAnswers.score_module_3)));
        for (const category in parsedAnswersModule3) {
          if (parsedAnswersModule3.hasOwnProperty(category)) {
            fullAnswers[category] = fullAnswers[category].map((item, index) => {
              return parsedAnswersModule3[category][index] || item;
            });
          }
        }
      }
      if(typeof answerModule4 !== null){
        const parsedAnswersModule4 = JSON.parse(savedAnswers.answer_module_4);
        setScoreModule4(parseToPercentage(JSON.parse(savedAnswers.score_module_4)));
        for (const category in parsedAnswersModule4) {
          if (parsedAnswersModule4.hasOwnProperty(category)) {
            fullAnswers[category] = fullAnswers[category].map((item, index) => {
              return parsedAnswersModule4[category][index] || item;
            });
          }
        }
      }              
      setAnswers(fullAnswers);

      const responseHistory = await fetch(`${Properties().baseUrl}/api/users/user/isNotLocked/${dataUser.id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
      },
      });
      if (!responseHistory.ok) {
        console.error('Error fetching user data:', responseHistory.statusText);
        return;
      } else {
        const dataHistory = await responseHistory.json();
        console.log("nilainya dataHistory", dataHistory);
        setUserAnswersHistory(dataHistory)
      }
    } catch (error) {
      console.error('Login error:', error);
      alert('Invalid credentials');
    }
  };

  const handleLogout = () => {
    localStorage.setItem('answers', JSON.stringify(defaultAnswers));
    localStorage.removeItem('userToken');
    localStorage.removeItem('answers');
    setIsLoggedIn(false);
    setUser(null);
    setCurrentModule('Module A');
    setCurrentChild(null);
    setSidebarOpen(true);
    setExpandedModule(null);
  };

  const isLastChild = () => {
    if (!expandedModule || !currentChild) return false;
    const childList = modules[expandedModule];
    return childList.indexOf(currentChild) === childList.length - 1;
  };

  const handleNextChild = () => {
    if (!expandedModule) return;

    const childList = modules[expandedModule];
    const currentIndex = childList.indexOf(currentChild);
    if (currentIndex < childList.length - 1) {
      setCurrentChild(childList[currentIndex + 1]);
    }
  };

  const handlePreviousChild = () => {
    if (!expandedModule) return;

    const childList = modules[expandedModule];
    const currentIndex = childList.indexOf(currentChild);
    if (currentIndex > 0) {
      setCurrentChild(childList[currentIndex - 1]);
    }
  };

  if (!isLoggedIn) {
    return <Login onLogin={handleLogin} />;
  }  

  return (
    <div className="app">
      <header className="header">
        <div className="logo-container">
          <img src={logoBanyuwangi} alt="Company Logo" className="company-logo" />
          <img src={logoPoliwangi} alt="New Logo" className="company-logo-2" />
        </div>
        <div className="title">
          IMPLEMENTASI PARIWISATA BERKELANJUTAN DAN PERANCANGAN PURWARUPA INDIKATOR PARIWISATA BERKELANJUTAN PADA INDUSTRI PERHOTELAN KABUPATEN BANYUWANGI
        </div>
        <div className="header-buttons">
          <IconButton onClick={() => setSidebarOpen(!sidebarOpen)}>
            {sidebarOpen ? <FaAngleLeft /> : <FaBars />}
          </IconButton>
          <button className="logout-button" onClick={handleLogout}>
            Logout
          </button>
        </div>
      </header>
      <div className="main-content">
        <div className={`sidebar ${sidebarOpen ? 'open' : 'closed'}`}>
          <div className="sidebar-content">
            <h2>Kriteria Industri</h2>
            {Object.keys(modules).map((module, index) => (
              <div key={index} className="module-box">
                <div
                  className={`module-header module-${module.toLowerCase().replace(' ', '-')} ${expandedModule === module ? 'active' : ''}`}
                  onClick={() => toggleModule(module)}
                >
                  <span>{module}</span>
                  {expandedModule === module ? <FaChevronDown /> : <FaChevronRight />}
                </div>
                {expandedModule === module && (
                  <div className="child-buttons">
                    {modules[module].map((child, idx) => (
                      <button
                        key={idx}
                        className={`child-button ${currentChild === child ? 'active' : ''}`}
                        onClick={() => {
                          setCurrentChild(child);
                        }}
                      >
                        {child}
                      </button>
                    ))}
                  </div>
                )}
              </div>
            ))}
            <div className="module-box">
              <div
                className={`module-header ${expandedModule === 'History' ? 'History' : ''}`}
                onClick={() => setExpandedModule('History')}
              >
                <span>History</span>
              </div>
            </div>
          </div>
        </div>

        <div className="question-list">
          {expandedModule !== 'History' && (
            <div className="content-header">
            {currentChild && <h1>{currentChild}</h1>}
            {expandedModule === 'Mempertunjukkan Pengelolaan Berkelanjutan secara Efektif' && (
              <div className="score-display">
              <p>Score: {scoreModule1 === null ? 0 : scoreModule1} %</p>
            </div>
            )}
            {expandedModule === 'Memaksimalkan manfaat sosial dan ekonomi bagi masyarakat lokal dan meminimalkan dampak negatif' && (
              <div className="score-display">
              <p>Score: {scoreModule2 === null ? 0 : scoreModule2} %</p>
            </div>
            )}
            {expandedModule === 'Memaksimalkan manfaat bagi warisan budaya dan meminimalkan dampak negatif' && (
              <div className="score-display">
              <p>Score: {scoreModule3 === null ? 0 : scoreModule3} %</p>
            </div>
            )}
            {expandedModule === 'Memaksimalkan manfaat bagi lingkungan dan meminimalkan dampak negatif' && (
              <div className="score-display">
              <p>Score: {scoreModule4 === null ? 0 : scoreModule4} %</p>
            </div>
            )}
          </div>
          )}
          {expandedModule !== 'History' && currentChild &&
            questionList &&
            questionList[currentChild].map((q, index) => (
              <div key={index} className="question-item">
                <p>{q.question}</p>
                <button
                  className={`answer-button ${answers[currentChild][index] === 'true' ? 'selected-true' : ''}`}
                  onClick={() => handleAnswer(index, 'true')}
                >
                  Ya
                </button>
                <button
                  className={`answer-button ${answers[currentChild][index] === 'false' ? 'selected-false' : ''}`}
                  onClick={() => handleAnswer(index, 'false')}
                >
                  Tidak
                </button>
              </div>
            ))}
          {expandedModule !== 'History' && currentChild && questionList[currentChild] && typeof modules[expandedModule] !== 'undefined' && (
            <>
              <div className="navigation-buttons">
                {(
                    modules[expandedModule].indexOf(currentChild) !== 0) && (
                    <button
                  onClick={handlePreviousChild}
                  disabled={
                    !currentChild ||
                    modules[expandedModule].indexOf(currentChild) === 0
                  }
                >
                  Previous
                </button>
              )}
                {!isLastChild() && (
                  <button
                    onClick={handleNextChild}
                    disabled={
                      !currentChild ||
                      modules[expandedModule] ===
                        modules[expandedModule].length - 1
                    }
                  >
                    Next
                  </button>
                )}
              </div>
            </>
          )}
          {expandedModule !== 'History' && isLastChild() && isModuleComplete(currentChild) && (
            <button className="submit-btn-final" onClick={() => submitAnswers(true)}>
              Submit Result
            </button>
          )}
           {expandedModule === 'History' && (
            <div className="history-content">
  <h2>History</h2>
  <table className="history-table">
    <thead>
      <tr>
        <th>Mempertunjukkan Pengelolaan Berkelanjutan secara Efektif</th>
        <th>Memaksimalkan manfaat sosial dan ekonomi bagi masyarakat lokal dan meminimalkan dampak negatif</th>
        <th>Memaksimalkan manfaat bagi warisan budaya dan meminimalkan dampak negatif</th>
        <th>Memaksimalkan manfaat bagi lingkungan dan meminimalkan dampak negatif</th>
        <th>Time</th>
      </tr>
    </thead>
    <tbody>
      {userAnswersHistory.map((item, index) => (
        <tr key={index}>
          <td>{parseToPercentage(item.score_module_1) || "-"}</td>
          <td>{parseToPercentage(item.score_module_2) || "-"}</td>
          <td>{parseToPercentage(item.score_module_3) || "-"}</td>
          <td>{parseToPercentage(item.score_module_4) || "-"}</td>
           <td>{new Date(item.created_at).toISOString().split('T')[0]}</td>
        </tr>
      ))}
    </tbody>
    <tfoot>
      <tr>
        <td colSpan="5" style={{ textAlign: 'center' }}>
          <button className="history-submit" onClick={openModal}>
            Final Result
          </button>
        </td>
      </tr>
    </tfoot>
  </table>
</div>
          )}
          {isModalOpen && (
            <div className="modal-overlay">
              <div className="modal-content">
                <h2>Are you sure?</h2>
                <p>If you click yes, all of your answers will be reset to default.</p>
                <button className="modal-button" onClick={handleConfirm}>Yes</button>
                <button className="modal-button" onClick={closeModal}>No</button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default App;
