const questionList = {
  'Sistem Pengelolaan Keberlanjutan': [
    { question: '1. Sistem pengelolaan berkelanjutan didokumentasikan dengan jelas', id: 1 },
    { question: '2. Sistem pengelolaan berkelanjutan meliputi isu-isu lingkungan, sosial, budaya, ekonomi, kualitas, hak asasi manusia, kesehatan dan keamanan', id: 2 },
    { question: '3. Sistem pengelolaan berkelanjutan mencakup pertimbangan tentang pengelolaan risiko dan krisis', id: 3 },
    { question: '4. Bukti terdokumentasi atas implementasi sistem pengelolaan berkelanjutan', id: 4 },
    { question: '5. Sistem pengelolaan berkelanjutan meliputi proses untuk memonitor perbaikan kinerja keberlanjutan yang berkesinambungan', id: 5 },
  ],
  'Kepatuhan terhadap hukum': [
    { question: '1. Daftar termutakhir semua persyaratan hukum yang berlaku selalu tersedia.', id: 1 },
    { question: '2. Sertifikat atau bukti dokumen lainnya menunjukkan kepatuhan terhadap semua persyaratan hukum yang berlaku.', id: 2 },
  ],
  'Pelaporan dan komunikasi': [
    { question: '1. Laporan reguler tentang kinerja keberlanjutan selalu tersedia', id: 1 },
    { question: '2. Kebijakan dan tindakan keberlanjutan dilaporkan dalam bahan komunikasi internal dan eksternal', id: 2 },
    { question: '3. Komunikasi keluar berisi pesan-pesan yang mengundang dukungan dari konsumen dan pemangku kepentingan.', id: 3 },
  ],
  'Pelibatan Staf': [
    { question: '1. Bukti pelibatan pegawai dalam sistem PB tersedia', id: 1 },
    { question: '2. Catatan tentang kursus dan praktik kerja pelatihan, dengan tingkat kehadiran peserta, tersedia.', id: 2 },
    { question: '3. Pelatihan staf dan bahan-bahan pemanduan tersedia dalam bentuk yang dapat diakses (termasuk penggunaan bahasa minoritas ketika diperlukan)', id: 3 },
    { question: '4. Staf memiliki sertifikat dan kualifikasi pada disiplin/keterampilan yang relevan.', id: 4 },
  ],
  'Pengalaman Pelanggan': [
    { question: '1. Sebuah sistem umpan balik konsumen tersedia, termasuk hasil analisisnya.', id: 1 },
    { question: '2. Umpan balik negatif dan penanganannya direkam.', id: 2 },
    { question: '3. Terdapat bukti tentang upaya perbaikan yang dilakukan.', id: 3 },
  ],
  'Promosi yang tepat': [
    { question: '1. Gambar-gambar yang digunakan dalam promosi mencerminkan pengalaman dan fasilitas yang sebenarnya', id: 1 },
    { question: '2. Klaim tentang keberlanjutan berdasarkan kepada catatan dari kinerja yang telah dijalani', id: 2 },
  ],
  'Kepatuhan': [
    { question: '1. Kepedulian dan kepatuhan kepada hukum tentang tata guna lahan dan kegiatan ditingkat lokal dipertunjukan.', id: 1 },
    { question: '2. Semua lisensi dan perijinan yang dipersyaratkan selalu diperbarui.', id: 2 },
    { question: '3. Kepedulian, dan kepatuhan, terhadap rencana dan petunjuk pengelolaan kawasan (seperti, antara lain, untuk zona tertentu, rancangan, dll.) dipertunjukan.', id: 3 },
  ],
  'Dampak dan keutuhan': [
    { question: '1. Pemilihan, rancangan dan akses kesitus telah mempertimbangkan dengan seksama amenitas visual, bentang alam, warisan budaya dan alam.', id: 1 },
    { question: '2. Pemilihan, rancangan dan akses kesitus telah mempertimbangkan perlindungan terhadap kawasan-kawasan yang sensitif secara biologi dan kapasitas asimilatif ekosistem.', id: 2 },
    { question: '3. Keutuhan situs-situs arkeologi, warisan budaya dan keramat, terawetkan.', id: 3 },
    { question: '4. Keutuhan dan konektivitas situs-situs alami dengan kawasan lindung, terawetkan.', id: 4 },
    { question: '5. Tidak ada species terancam atau dilindungi yang hilang, dan dampak terhadap semua habitat hidupan-liar telah diminimalkan dan dimitigasi.', id: 5 },
    { question: '6. Tidak ada jalur air/daerah-tangkapan/lahan-basah yang diubah, dan bila memungkinkan air yang terbuang selalu diminimalkan dan setiap residu ditampung atau disalurkan dan disaring.', id: 6 },
    { question: '7. Faktor-faktor risiko (termasuk perubahan iklim, fenomena alami, dan keamanan pengunjung) telah dikaji dan ditangani.', id: 7 },
    { question: '8. Kajian dampak (termasuk dampak kumulatif) telah dilakukan dan dikomentarkan.', id: 8 },
  ],
  'Praktik dan Bahan Berkelanjutan': [
    { question: '1. Bahan, praktik dan kerajinan lokal digunakan pada bangunan dan rancangan, jika memungkinkan dan sesuai.', id: 1 },
    { question: '2. Tanaman asli dan endemik yang diperoleh dari sumber-sumber berkelanjutan digunakan untuk pertamanan dan dekorasi, dan tidak menggunakan species eksotis dan invasif.', id: 2 },
    { question: '3. Pemilihan tanaman berdasarkan kepada kemampuan mereka untuk menghadapi kondisi cuaca saat ini atau yang akan datang, antara lain, tanaman yang tahan terhadap kekeringan.', id: 3 },
    { question: '4. Perancangan, bahan dan praktik konstruksi yang berkelanjutan digunakan pada bangunan, dengan sertifikasi yang sesuai bila memungkinkan.', id: 4 },
    { question: '5. Limbah konstruksi dipilah dan dibuang dengan cara yang ramah lingkungan.', id: 5 },
  ],
  'Akses untuk semua': [
    { question: '1. Situs, bangunan dan kegiatan dapat diakses oleh orang yang memiliki keterbatasan fisik dan yang berkebutuhan khusus lainnya, sesuai dengan bagaimana operasi dilakukan.', id: 1 },
    { question: '2. Informasi yang jelas dan akurat diberikan berdasarkan tingkatan aksesibilitas.', id: 2 },
    { question: '3. Aksesibilitas tersertifikasi dan diperiksa oleh badan pakar/pengguna yang relevan.', id: 3 },
  ],
  'Hak atas lahan, air dan kepemilikan': [
      { question: '1. Kepemilikan lahan dan hak tenurial didokumentasikan.', id: 1 },
      { question: '2. Hak pengguna dan akses terhadap sumberdaya penting, seperti lahan dan air, bila memungkinkan didokumentasikan.', id: 2 },
      { question: '3. Ada bukti terdokumentasi tentang komunikasi dan konsultasi dengan, serta pelibatan masyarakat lokal dan asli.', id: 3 },
      { question: '4. Bukti bahwa persetujuan atas dasar informasi awal tanpa paksaan dengan masyarakat lokal didokumentasikan, bila relevan (menunjukkan bahwa tidak ada pemukiman-kembali atau pengambil-alihan lahan secara paksa).', id: 4 },
    ],
  'Informasi dan interpretasi': [
      { question: '1. Bahan informasi/interpretasi tentang warisan-warisan alam dan budaya di kawasan setempat tersedia dan diberikan kepada pelanggan.', id: 1 },
      { question: '2. Staf diberi informasi dan dilatih tentang warisan-warisan alam dan budaya di kawasan setempat.', id: 2 },
      { question: '3. Pelanggan diberi informasi tentang perilaku yang sesuai di kawasan setempat.', id: 3 },
  ],
  'Pelibatan destinasi': [
      { question: '1. Organisasi menjadi anggota dari Organisasi Pengelola Destinasi lokal atau badan setara lainnya, bila organisasi tersebut ada.', id: 1 },
      { question: '2. Organisasi berpartisipasi dalam kemitraan dengan masyarakat, LSM dan badan lokal lainnya, bila mereka ada.', id: 2 },
      { question: '3. Organisasi berpartisipasi dalam pertemuan-pertemuan perencanaan dan pengelolaan, serta kegiatan-kegiatan terkait dengan pariwisata berkelanjutan di destinasi.', id: 3 },
  ],
  'Dukungan terhadap masyarakat': [
      { question: '1. Organisasi melibatkan masyarakat lokal dalam mengidentifikasi kebutuhan dan peluang untuk memberi dukungan, dan mengevaluas potensi manfaat/dampaknya.', id: 1 },
      { question: '2. Tingkat dan bentuk kontribusi yang diberikan kepada masyarakat lokal dicatat.', id: 2 },
      { question: '3. Masyarakat lokal ditawari kesempatan untuk memasuki fasilitas pariwisata dan mencoba layanan yang diberikan.', id: 3 },
  ],
  'Pekerjaan bagi masyarakat lokal': [
      { question: '1. Proporsi terhadap lapangan pekerjaan total dari orang-orang yang sudah tinggal dengan masyarakat lokal diukur dan dikelola.', id: 1 },
      { question: '2. Proporsi jabatan pada posisi pengelola dari orang-orang yang sudah tinggal dengan masyarakat lokal diukur dan dikelola.', id: 2 },
      { question: '3. Pelatihan ditawarkan kepada masyarakat lokal untuk meningkatkan peluang mereka mendapatkan pekerjaan.', id: 3 },
  ],
  'Pembelian barang dan jasa lokal': [
      { question: '1. Organisasi secara teratur mengaudit sumber-sumber pasokan barang dan jasa yang digunakannya.', id: 1 },
      { question: '2. Proporsi barang dan jasa yang dibeli dari bisnis milik dan dilakukan oleh orang lokal diukur dan dikelola.', id: 2 },
      { question: '3. Proporsi pemasok yang bukan milik dan tidak dilakukan oleh orang lokal yang menerapkan perdagangan berkeadilan diukur dan dikelola.', id: 3 },
  ],
  'Wirausahawan lokal': [
      { question: '1. Bisnis yang dimiliki orang lokal diberi akses tempat dan melakukan kegiatan komersial kepada pelanggan hotel.', id: 1 },
      { question: '2. Bila diperlukan, organisasi dapat memberikan saran dan dukungan kepada pemberi jasa lokal yang dilibatkan, tentang kualitas dan keberlanjutan jasa mereka.', id: 2 },
      { question: '3. Peluang untuk bekerjasama dan bermitra dengan wirausahawan lokal dipertimbangkan dan dilakukan jika memungkinkan.', id: 3 },
  ],
  'Eksploitasi dan pelecehan': [
      { question: '1. Organisasi memiliki kebijakan terdokumentasi untuk menentang eksploitasi dan pelecehan terhadap kelompok rentan.', id: 1 },
      { question: '2. Tindakan dilakukan untuk mengkomunikasikan dan melaksanakan kebijakan tersebut.', id: 2 },
      { question: '3. Organisasi melibatkan masyarakat lokal dalam kerja-kerja menentang eksploitasi dan pelecehan.', id: 3 },
      { question: '4. Catatan tentang usia pegawai disimpan untuk menunjukkan tidak adanya segala bentuk buruh anak (sesuai dengan definisi ILO).', id: 4 },
      { question: '5. Organisasi mendukung semua tindakan menentang pariwisata seks anak.', id: 5 },
  ],
  'Peluang yang sama': [
      { question: '1. Organisasi telah mengidentifikasi kelompok-kelompok yang berisiko mengalami diskriminasi, termasuk perempuan dan minoritas lokal.', id: 1 },
      { question: '2. Proporsi pegawai yang datang dari kelompok rentan tersebut dimonitor dan sepadan dengan demografi lokal.', id: 2 },
      { question: '3. Promosi internal juga diberikan kepada kelompok rentan tersebut.', id: 3 },
  ],
  'Kerja yang layak': [
      { question: '1. Organisasi mempertunjukkan kepedulian dan kepatuhan kepada standar dan peraturan perburuhan internasional.', id: 1 },
      { question: '2. Tingkat gaji dimonitor dan secara teratur ditinjau-ulang mengacu kepada standar biaya hidup nasional.', id: 2 },
      { question: '3. Catatan pelatihan semua staf disimpan, menunjukkan tingkat dan frekuensi pelatihan yang diterima.', id: 3 },
      { question: '4. Kontrak pegawai menunjukkan adanya dukungan untuk pemeliharaan kesehatan dan jaminan sosial.', id: 4 },
      { question: '5. Fasilitas air, sanitasi dan kebersihan diberikan kepada semua pegawai di tempat kerja.', id: 5 },
      { question: '6. Kepuasan pegawai dimonitor.', id: 6 },
      { question: '7. Ada mekanisme bagi pegawai untuk menyampaikan keluhan.', id: 7 },
  ],
  'Layanan masyarakat': [
      { question: '1. Organisasi memonitor dampak kegiatannya terhadap ketersediaan layanan lokal.', id: 1 },
      { question: '2. Tersedia mekanisme komunikasi/umpan-balik/penyampaian keluhan bagi masyarakat lokal.', id: 2 },
      { question: '3. Setiap pengurangan ketersediaan layanan dasar kepada masyarakat lokal, dan diidentifikasi karena disebabkan oleh kegiatan-kegiatan organisasi, ditangani.', id: 3 },
  ],
  'Mata-pencaharian lokal': [
      { question: '1. Akses masyarakat lokal terhadap mata-pencaharian selalu dipertimbangkan dalam keputusan terkait dengan pembangunan dan operasi.', id: 1 },
      { question: '2. Tersedia mekanisme komunikasi bagi masyarakat lokal untuk melaporkan setiap pengurangan akses terhadap mata-pencaharian lokal.', id: 2 },
  ],
  'Interaksi budaya': [
      { question: '1. Organisasi mempertunjukkan kepedulian dan kepatuhan terhadap praktik yang baik dan panduan wisatawan, yang berlaku secara internasional, nasional dan lokal, saat mengunjungi situs-situs budaya dan masyarakat asli.', id: 1 },
      { question: '2. Organisasi melibatkan masyarakat/situs dalam meninjau panduan, dan menciptakan dan menyepakati panduan tambahan bila diperlukan.', id: 2 },
      { question: '3. Panduan digunakan dan dikomunikasikan secara efektif.', id: 3 },
      { question: '4. Tersedia tindakan khusus untuk menghindari interaksi yang tidak pantas dengan anak-anak.', id: 4 },
  ],
  'Melindungi warisan budaya': [
      { question: '1. Organisasi membuat dan mencatat sumbangan dana yang diberikan untuk melindungi warisan budaya.', id: 1 },
      { question: '2. Organisasi memberikan dukungan berupa barang atau bentuk lainnya untuk warisan budaya.', id: 2 },
      { question: '3. Akses diberikan kepada masyarakat lokal untuk memasuki situs-situs.', id: 3 },
  ],
  'Menyajikan budaya dan warisan': [
      { question: '1. Seni/kerajinan lokal tercermin pada rancangan dan perabotan.', id: 1 },
      { question: '2. Tradisi dan warisan budaya tercermin pada makanan, produk eceran, atau kegiatan.', id: 2 },
      { question: '3. Pengunjung diberi kesempatan untuk melihat/mengalami aspek-aspek budaya dan warisan yang ditampilkan.', id: 3 },
  ],
  'Artefak': [
      { question: '1. Setiap penggunaan artefak dilakukan secara transparan dan/atau didokumentasikan dan dilaporkan.', id: 1 },
      { question: '2. Ketika artefak digunakan, semua hukum dan peraturan terkait dengan penggunaannya telah diidentifikasi.', id: 2 },
      { question: '3. Pengunjung dicegah untuk memindahkan atau merusak artefak.', id: 3 },
  ],
  'Pembelian yang ramah-lingkungan': [
      { question: '1. Dokumentasi kebijakan pembelian yang ramah-lingkungan tersedia.', id: 1 },
      { question: '2. Pilihan diutamakan kepada produk dan pemasok yang memiliki sertifikasi lingkungan – terutama yang berhubungan dengan kayu, kertas, ikan, makanan lain, dan produk dari alam.', id: 2 },
      { question: '3. Ketika produk dan pemasok tersertifikasi tidak ada, pertimbangan diberikan terhadap asal-muasal dan cara untuk menumbuhkan atau berproduksi.', id: 3 },
      { question: '4. Species terancam tidak digunakan atau dijual.', id: 4 },
  ],
  'Pembelian yang efisien': [
      { question: '1. Pembelian mengutamakan barang-barang yang dapat dipakai-ulang, dapat dikembalikan dan didaur-ulang.', id: 1 },
      { question: '2. Pembelian dan penggunaan barang-barang habis dan sekali pakai dimonitor dan dikelola.', id: 2 },
      { question: '3. Kemasan yang tidak perlu (terutama dari plastik) sedapat mungkin dihindari, dengan pembelian dalam jumlah yang sesuai.', id: 3 },
  ],
  'Konservasi energi': [
      { question: '1. Energi total yang digunakan dimonitor dan dikelola.', id: 1 },
      { question: '2. Energi yang digunakan oleh setiap wisatawan/hari untuk setiap jenis energi dimonitor dan dikelola.', id: 2 },
      { question: '3. Sumber-sumber terbarukan lebih diutamakan, dan bagian energi terbarukan pada pasokan energi total dimonitor dan dikelola.', id: 3 },
      { question: '4. Peralatan dan praktik yang digunakan adalah yang meminimalkan penggunaan energi.', id: 4 },
      { question: '5. Tujuan dari pengurangan konsumsi energi dinyatakan.', id: 5 },
      { question: '6. Staf dan tamu diberi panduan untuk meminimalkan penggunaan energi.', id: 6 },
  ],
  'Konservasi air': [
      { question: '1. Risiko air dikaji dan didokumentasikan.', id: 1 },
      { question: '2. Ketika hasil kajian menunjukkan risiko air yang tinggi, tujuan penatalayanan air harus ditentukan.', id: 2 },
      { question: '3. Air yang digunakan oleh setiap wisatawan/hari untuk setiap sumber, dimonitor dan dikelola.', id: 3 },
      { question: '4. Peralatan dan praktik yang digunakan adalah yang meminimalkan konsumsi air.', id: 4 },
      { question: '5. Air berasal dari sumber yang legal dan berkelanjutan dan tidak pernah, serta kemungkinan besar juga tidak di masa depan, mempengaruhi aliran/pasokannya di alam.', id: 5 },
      { question: '6. Pertimbangan diberikan kepada dampak kumulatif kegiatan pariwisata setempat terhadap sumber air.', id: 6 },
      { question: '7. Tujuan dari pengurangan konsumsi air ditetapkan.', id: 7 },
      { question: '8. Staf dan tamu diberi panduan untuk meminimalkan penggunaan air.', id: 8 },
  ],
  'Emisi gas rumah-kaca': [
      { question: '1. Emisi gas rumah-kaca total langsung dan tak-langsung dimonitor dan dikelola.', id: 1 },
      { question: '2. Jejak karbon setiap wisatawan/hari dimonitor dan dikelola.', id: 2 },
      { question: '3. Tindakan diambil untuk menghindari dan mengurangi emisi tahunan dari semua sumber-sumber yang berada di bawah kendali organisasi.', id: 3 },
      { question: '4. Mekanisme kompensasi karbon digunakan bila memungkinkan.', id: 4 },
  ],
  'Transportasi': [
      { question: '1. Informasi diberikan dan dipromosikan kepada pelanggan tentang pilihan transportasi alternatif (yang ramah iklim) untuk kedatangan, keberangkatan dan selama dalam kunjungan.', id: 1 },
      { question: '2. Pilihan transportasi alternatif (antara lain, sewa sepeda, berbagi mobil, penjemputan) bagi tamu dan staf diberikan atau difasilitasi.', id: 2 },
      { question: '3. Pasar yang dapat dicapai melalui perjalanan pendek dengan menggunakan pilihan transportasi yang berkelanjutan lebih diutamakan.', id: 3 },
      { question: '4. Pemasok lokal diutamakan dan operasi harian diupayakan meminimalkan penggunaan transportasi.', id: 4 },
  ],
  'Air limbah': [
      { question: '1. Limbah air dibuang ke sistem pengelolaan limbah milik pemerintah atau yang diijinkannya, bila tersedia.', id: 1 },
      { question: '2. Bila instalasi pengelolaan air limbah milik pemerintah tidak tersedia, maka organisasi wajib memiliki sarana pengolahan limbah yang memadai di dalam situsnya (yang memenuhi persyaratan kualitas air limbah internasional) dan menjamin tidak ada pengaruh buruk terhadap penduduk dan lingkungan lokal.', id: 2 },
  ],
  'Limbah padat': [
      { question: '1. Jumlah limbah padat yang dibuang setiap wisatawan/hari dimonitor dan dikelola.', id: 1 },
      { question: '2. Tersedia rencana pengelolaan limbah padat.', id: 2 },
      { question: '3. Rencana pengelolaan limbah padat mencakup tindakan-tindakan untuk mengurangi, memisahkan dan memakai-ulang atau mendaur-ulang limbah makanan.', id: 3 },
      { question: '4. Limbah dibuang ke fasilitas pengelolaan limbah milik pemerintah atau yang diijinkan, dan terbukti tidak menimbulkan dampak negatif terhadap penduduk dan lingkungan lokal.', id: 4 },
      { question: '5. Limbah padat yang dibuang diukur berdasarkan jenisnya, dan tujuan dinyatakan untuk meminimalkan limbah padat yang tidak dapat dialihkan.', id: 5 },
      { question: '6. Panduan diberikan kepada pelanggan dan staf untuk meminimalkan limbah.', id: 6 },
  ],
  'Bahan berbahaya dan beracun': [
      { question: '1. Daftar inventaris bahan-bahan berbahaya tersedia, dan lembar data keamanan bahan (MSDS: material safety data sheets) dibuat dan disimpan.', id: 1 },
      { question: '2. Tindakan diambil untuk mendapatkan bahan alternatif yang lebih ramah lingkungan.', id: 2 },
      { question: '3. Bahan-bahan kimia, khususnya yang bervolume besar, disimpan dan ditangani menurut standar yang sesuai.', id: 3 },
      { question: '4. Pengunjung diberi informasi tentang penggunaan bahan-bahan untuk keperluan pribadi yang dinilai dapat membahayakan lingkungan lokal (seperti pelindung-matahari dan penolak-serangga yang beracun).', id: 4 },
  ],
  'Meminimalkan pencemaran': [
      { question: '1. Potensi sumber polusi yang tercakup dalam kriteria telah ditinjau dan diidentifikasi.', id: 1 },
      { question: '2. Potensi sumber polusi yang tercakup dalam kriteria dimonitor.', id: 2 },
      { question: '3. Tindakan diambil untuk meminimalkan dan bila mungkin menghilangkan pencemaran yang diliput dalam kriteria.', id: 3 },
  ],
  'Konservasi keanekaragaman hayati': [
      { question: '1. Organisasi mempertunjukkan kepedulian terhadap kawasan pelindungan alam dan kawasan yang memiliki nilai keanekaragaman hayati tinggi.', id: 1 },
      { question: '2. Organisasi memberikan dan mencatat dukungan pendanaan bagi konservasi keanekaragaman hayati di kawasan setempat.', id: 2 },
      { question: '3. Organisasi memberikan dukungan dalam bentuk barang atau lainnya untuk konservasi keanekaragaman hayati di kawasan setempat.', id: 3 },
      { question: '4. Properti dikelola secara aktif untuk mendukung konservasi keanekaragaman hayati.', id: 4 },
      { question: '5. Organisasi peduli dan memitigasi kegiatan-kegiatan yang berpotensi mengganggu hidupan-liar dan habitatnya.', id: 5 },
      { question: '6. Kompensasi diberikan ketika gangguan terjadi.', id: 6 },
      { question: '7. Tindakan diambil untuk mendorong pengunjung mendukung konservasi keanekaragaman hayati.', id: 7 },
      { question: '8. Organisasi melibatkan LSM konservasi lokal.', id: 8 },
  ],
  'Species invasif': [
      { question: '1. Kehadiran setiap species invasif di situs-situs dimonitor.', id: 1 },
      { question: '2. Tindakan diambil untuk menjamin species invasif tidak masuk dan meluas.', id: 2 },
      { question: '3. Tersedia program untuk membasmi dan mengendalikan species invasif.', id: 3 },
      { question: '4. Pertamanan dalam situs ditinjau untuk mempertimbangkan penggunaan species asli setempat.', id: 4 },
  ],
  'Kunjungan ke situs alami': [
      { question: '1. Organisasi peduli dan patuh kepada panduan yang ada tentang kunjungan wisatawan ke situs-situs alami.', id: 1 },
      { question: '2. Panduan digunakan ketika melakukan kunjungan dan memberi informasi kepada para tamu.', id: 2 },
      { question: '3. Organisasi melibatkan badan konservasi lokal untuk menetapkan/ mengidentifikasi isu-isu terkait dengan kunjungan ke situs-situs tertentu.', id: 3 },
  ],
  'Interaksi dengan hidupan-liar': [
      { question: '1. Organisasi peduli dan patuh kepada peraturan dan panduan lokal, nasional dan internasional tentang interaksi dengan hidupan-liar, termasuk pengamatan hidupan-liar.', id: 1 },
      { question: '2. Organisasi terlibat dalam pengembangan dan implementasi kode dan panduan lokal bagi interaksi dengan hidupan-liar, termasuk pengamatan hidupan-liar, sesuai ketentuan, berdasarkan saran dari pakar hidupan-liar.', id: 2 },
      { question: '3. Interaksi langsung, apalagi memberi pakan, tidak diperbolehkan, kecuali secara khusus mengikuti standar internasional atau, bila standar tidak tersedia, dipandu oleh saran dari pakar hidupan-liar independen.', id: 3 },
      { question: '4. Upaya untuk meminimalkan gangguan terhadap hidupan-liar dilakukan.', id: 4 },
      { question: '5. Dampak terhadap kesejahteraan hidupan-liar dimonitor dan ditangani secara reguler.', id: 5 },
  ],
  'Kesejahteraan satwa': [
      { question: '1. Organisasi peduli dan patuh terhadap hukum dan peraturan yang relevan tentang penangkaran hidupan-liar.', id: 1 },
      { question: '2. Panduan yang ada bagi kegiatan pariwisata khusus yang melibatkan hidupan-liar yang ditangkar dilaksanakan.', id: 2 },
      { question: '3. Personil yang bertanggungjawab terhadap hidupan-liar yang ditangkar memiliki kualifikasi dan pengalaman yang sesuai, dan berlisensi penuh.', id: 3 },
      { question: '4. Organisasi peduli dan patuh terhadap hukum dan peraturan yang relevan tentang kesejahteraan satwa.', id: 4 },
      { question: '5. Inspeksi terhadap kondisi hidupan-liar dan kandangnya dilakukan secara reguler.', id: 5 },
      { question: '6. Inspeksi terhadap kondisi hewan peliharaan dan kandang dan penanganannya dilakukan secara reguler.', id: 6 },
  ],
  'Pengambilan dan perdagangan hidupan-liar': [
      { question: '1. Organisasi peduli dan patuh kepada hukum dan peraturan yang relevan tentang pengambilan dan perdagangan hidupan-liar.', id: 1 },
      { question: '2. Pengunjung diberi informasi tentang peraturan terkait dengan pengambilan, konsumsi dan perdagangan hidupan-liar, dan perlunya tidak membeli produk/cenderamata ilegal yang berasal dari species hidupan-liar terancam yang diumumkan oleh IUCN atau CITES.', id: 2 },
      { question: '3. Bila kegiatan berburu dapat dilakukan secara legal, ia merupakan bagian dari pendekatan konservasi berbasis sains yang dikelola dengan baik dan dilakukan secara ketat.', id: 3 },
  ]
};

export default questionList;