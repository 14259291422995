// src/Register.js
import React, { useState } from 'react';
import './Login.css';
import { TextField, Button, Container, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom'; // Assuming you're using React Router for navigation
import Properties from './properties';

const Register = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log("nilainya properties", Properties().baseUrl);
      const response = await fetch(`${Properties().baseUrl}/api/users/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });
      if (!response.ok) {
        throw new Error('Registration failed');
      }
      // Navigate to login or another page on success
      navigate('/');
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="login-page">
    <Container maxWidth="xs" className="login-container">
      <Typography variant="h4" className="login-title">
        Register
      </Typography>
      <form onSubmit={handleSubmit}>
       <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            name="email"
            autoComplete="email"
            placeholder='Email'
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="login-input"
        />
        <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            placeholder='Password'
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="login-input"
        />
        {error && <Typography color="error">{error}</Typography>}
        <Button type="submit" variant="contained" color="primary" className="login-button" fullWidth>
          Register
        </Button>
      </form>
    </Container>
    </div>
  );
};

export default Register;
